const contentful = require('contentful')
const form = document.querySelector('form');
const optionsList = document.getElementById("options");
const location = document.getElementById("location");
const button = document.querySelector('button');
const loader = document.querySelector('#loader');
const removeNumber = document.getElementById("remove-number");
const linkList = document.getElementById("links");

const client = contentful.createClient({
  space: 'aiyr4vhm7nmj',
  environment: 'master', // defaults to 'master' if not set
  accessToken: 'O0qDmlYCx5jb-6-MGiq0wo_91DOCJnm-9GejuY7wjqs'
})


const getOptions = client.getEntries({
  content_type: 'option',
  order: 'fields.name'
})
  .then((response) => response.items)
  .catch(console.error)


const options = getOptions.then((response) => {
  return response;
})

// create options from options
options.then((response) => {
  response.forEach((item) => {
    let option = document.createElement("label");
    option.classList.add("option");
    option.innerHTML = `<input type="checkbox" value="${item.fields.name}">${item.fields.name}`;
    optionsList.appendChild(option);
  })
})

const getLinks = client.getEntries({
  content_type: 'links',
  order: 'fields.title'
})
  .then((response) => response.items)
  .catch(console.error)

const links = getLinks.then((response) => {
  return response;
})

// create links from contentful
links.then((response) => {
  response.forEach((item) => {
    let link = document.createElement("a");
    link.classList.add("link");
    link.setAttribute("href", item.fields.link);
    link.setAttribute("target", "_blank");
    link.innerHTML = `${item.fields.title}`;
    linkList.appendChild(link);
  })
})

function geoFindMe() {
  function success(position) {
    const latitude  = position.coords.latitude;
    const longitude = position.coords.longitude;
    location.value = `${latitude},${longitude}`;
    button.removeAttribute("disabled");
    loader.classList.add("hidden");
  }

  function error() {
    alert("Konumunuz bulunamadı. Lütfen konum izinlerinizi kontrol edip, tekrar deneyin.");
  }


  if (!navigator.geolocation) {
    alert("Geolocation is not supported by your browser");
  } else {;
    navigator.geolocation.getCurrentPosition(success, error);
    //disable button with attribute
    button.setAttribute("disabled", "disabled");
  }

}

geoFindMe();
//click allow location button



// for from contentful


removeNumber.addEventListener("click", () => {
  localStorage.removeItem("phone");
  document.getElementById("phone").value = "";
});


form.addEventListener('submit', (e) => {
  e.preventDefault();

  let selectedOptions = [];
  let checkboxes = document.querySelectorAll('input[type=checkbox]:checked');
  for (let i = 0; i < checkboxes.length; i++) {
    //selectedOptions.push(checkboxes[i].value);
    selectedOptions.push(checkboxes[i].parentNode.innerText);
  }

  //save selected options to localstorage
  localStorage.setItem("options", selectedOptions);

  let phone = document.getElementById("phone").value;
  let location = document.getElementById("location");
  let data = {
    phone: phone,
    location: location.value,
    options: selectedOptions
  }

  data.options = data.options.join(", ");
  data.options = data.options.charAt(0).toUpperCase() + data.options.slice(1).toLowerCase();



  // save phone number to localstorage
  localStorage.setItem("phone", data.phone);

  //if number change update localstorage
  if (localStorage.getItem("phone") != data.phone) {
    localStorage.setItem("phone", data.phone);
  } else{
    //update phone value with localstorage
    data.phone = localStorage.getItem("phone");
  }




  if (data.options != "") {
    window.location.href = `https://twitter.com/intent/tweet?&text=${data.options} ihtiyacı var. Lütfen bize ulaşın. Tel:${data.phone} @AFADBaskanlik @ahbap @Kizilay 📍Konum: https://google.com/maps/place/${data.location}&hashtags=deprem,yardım`;
  } else {
    window.location.href = `https://twitter.com/intent/tweet?&text=Yardıma ihtiyacımız var. Lütfen bize ulaşın. Tel:${data.phone} @AFADBaskanlik @ahbap @Kizilay 📍Konum: https://google.com/maps/place/${data.location}&hashtags=deprem,yardım`;
  }

});



